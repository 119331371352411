import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SummaryApi from '../common';
import { FaStar, FaStarHalf } from "react-icons/fa";
import displayINRCurrency from '../helpers/displayCurrency';
import CategroyWiseProductDisplay from '../components/CategoryWiseProductDisplay';
import addToCart from '../helpers/addToCart';
import Context from '../context';


const ProductDetails = () => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: ""
  });
  const [activeImage, setActiveImage] = useState("");
  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({ x: 0, y: 0 });
  const [zoomImage, setZoomImage] = useState(false);
  const [selectedSize, setSelectedSize] = useState(""); // State for selected size
  const [loading, setLoading] = useState(true);
   const [reviews, setReviews] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [newReview, setNewReview] = useState("");
  const [newQuestion, setNewQuestion] = useState("");

   

  const params = useParams();
  const { fetchUserAddToCart } = useContext(Context);
  const navigate = useNavigate();
  const handleReviewSubmit = (e) => {
    e.preventDefault();
    setReviews([...reviews, { text: newReview }]);
    setNewReview("");
  };

  const handleQuestionSubmit = (e) => {
    e.preventDefault();
    setQuestions([...questions, { text: newQuestion }]);
    setNewQuestion("");
  };
  const fetchProductDetails = async () => {
    setLoading(true);
    const response = await fetch(SummaryApi.productDetails.url, {
      method: SummaryApi.productDetails.method,
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ productId: params?.id })
    });
    setLoading(false);
    const dataReponse = await response.json();
    setData(dataReponse?.data);
    setActiveImage(dataReponse?.data?.productImage[0]);
  };

  useEffect(() => {
    fetchProductDetails();
  }, [params]);

  const handleMouseEnterProduct = (imageURL) => {
    setActiveImage(imageURL);
  };

  const handleZoomImage = useCallback((e) => {
    setZoomImage(true);
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    setZoomImageCoordinate({ x, y });
  }, []);

  const handleLeaveImageZoom = () => {
    setZoomImage(false);
  };

  const handleAddToCart = async (e, id) => {
    await addToCart(e, id, selectedSize);
    fetchUserAddToCart();
  };

  const handleBuyProduct = async (e, id) => {
    await addToCart(e, id, selectedSize);
    fetchUserAddToCart();
    navigate("/cart");
  };

  const renderSizeSelector = () => (
    <div className="my-2">
      <p className="text-slate-600 font-medium">Select Size:</p>
      <div className="flex gap-2">
        {["S", "M", "L", "XL"].map(size => (
          <button
            key={size}
            className={`border-2 rounded px-4 py-2 font-medium ${selectedSize === size ? 'border-red-600 bg-red-600 text-white' : 'border-slate-300 hover:border-red-600'}`}
            onClick={() => setSelectedSize(size)}
          >
            {size}
          </button>
        ))}
      </div>
    </div>
  );

  return (
    <div className='container mx-auto p-4'>
      <div className='min-h-[200px] flex flex-col lg:flex-row gap-4'>
        {/*** Product Image Gallery */}
        <div className='h-96 flex flex-col lg:flex-row-reverse gap-4'>
          <div className='h-[300px] w-[300px] lg:h-96 lg:w-96 bg-slate-200 relative p-2'>
            <img
              src={activeImage}
              className='h-full w-full object-scale-down mix-blend-multiply'
              onMouseMove={handleZoomImage}
              onMouseLeave={handleLeaveImageZoom}
            />

            {/** Zoomed Image View */}
            {zoomImage && (
              <div className='hidden lg:block absolute min-w-[500px] overflow-hidden min-h-[400px] bg-slate-200 p-1 -right-[510px] top-0'>
                <div
                  className='w-full h-full min-h-[400px] min-w-[500px] mix-blend-multiply scale-150'
                  style={{
                    background: `url(${activeImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`
                  }}
                />
              </div>
            )}
          </div>

          {/** Image Thumbnail List */}
          <div className='h-full'>
            {loading ? (
              <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                {new Array(4).fill(null).map((_, index) => (
                  <div className='h-20 w-20 bg-slate-200 rounded animate-pulse' key={`loadingImage${index}`}></div>
                ))}
              </div>
            ) : (
              <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                {data?.productImage?.map((imgURL, index) => (
                  <div className='h-20 w-20 bg-slate-200 rounded p-1' key={imgURL}>
                    <img
                      src={imgURL}
                      className='w-full h-full object-scale-down mix-blend-multiply cursor-pointer'
                      onMouseEnter={() => handleMouseEnterProduct(imgURL)}
                      onClick={() => handleMouseEnterProduct(imgURL)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/** Product Details */}
        <div className='flex flex-col gap-1'>
          <p className='bg-red-200 text-red-600 px-2 rounded-full inline-block w-fit'>{data?.brandName}</p>
          <h2 className='text-2xl lg:text-4xl font-medium'>{data?.productName}</h2>
          <p className='capitalize text-slate-400'>{data?.category}</p>

          <div className='text-red-600 flex items-center gap-1'>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalf />
          </div>

          <div className='flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1'>
            <p className='text-red-600'>{displayINRCurrency(data.sellingPrice)}</p>
            <p className='text-slate-400 line-through'>{displayINRCurrency(data.price)}</p>
          </div>

          {/** Size Selector for T-shirts */}
          {data?.category === "T-shirt" && renderSizeSelector()}

          <div className='flex items-center gap-3 my-2'>
            <button
              className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] text-red-600 font-medium hover:bg-red-600 hover:text-white'
              onClick={(e) => handleBuyProduct(e, data?._id)}
            >
              Buy
            </button>
            <button
              className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] font-medium text-white bg-red-600 hover:text-red-600 hover:bg-white'
              onClick={(e) => handleAddToCart(e, data?._id)}
            >
              Add To Cart
            </button>
          </div>

          <div>
            <p className='text-slate-600 font-medium my-1'>Description:</p>
            <p>{data?.description}</p>
          </div>
        </div>
      </div>
       {/* Customer Reviews and Q&A Section */}
      <div className='flex gap-4 my-6'>
        {/* Customer Reviews Section */}
        <div className='max-w-sm bg-slate-100 p-4 rounded shadow-md flex-1'>
          <h3 className='text-xl font-semibold mb-2'>Customer Reviews</h3>
          <form onSubmit={handleReviewSubmit} className='flex flex-col mb-4'>
            <textarea
              className='border p-2 rounded mb-2 resize-none'
              placeholder='Write a review...'
              value={newReview}
              onChange={(e) => setNewReview(e.target.value)}
              required
            />
            <button className='bg-red-600 text-white rounded px-4 py-2' type='submit'>Submit Review</button>
          </form>
          {reviews.length === 0 ? (
            <p>No reviews yet.</p>
          ) : (
            reviews.map((review, index) => (
              <div key={index} className='border-b py-2'>
                <p>{review.text}</p>
              </div>
            ))
          )}
        </div>

        {/* Q&A Section */}
        <div className='max-w-sm bg-slate-100 p-4 rounded shadow-md flex-1'>
          <h3 className='text-xl font-semibold mb-2'>Questions & Answers</h3>
          <form onSubmit={handleQuestionSubmit} className='flex flex-col mb-4'>
            <textarea
              className='border p-2 rounded mb-2 resize-none'
              placeholder='Ask a question...'
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              required
            />
            <button className='bg-red-600 text-white rounded px-4 py-2' type='submit'>Submit Question</button>
          </form>
          {questions.length === 0 ? (
            <p>No questions yet.</p>
          ) : (
            questions.map((question, index) => (
              <div key={index} className='border-b py-2'>
                <p>{question.text}</p>
              </div>
            ))
          )}
        </div>
      </div>

      {/** Category-wise product display */}
      {data?.category && <CategroyWiseProductDisplay category={data?.category} heading={"Recommended Product"} />}
    </div>
  );
};

export default ProductDetails;
