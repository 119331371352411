import React, { useEffect, useState } from 'react';
import SpinWheel from '../components/SpinWheel';
import ScratchCard from '../components/ScratchCard';

const DealsPage = () => {
  const [isSpinWheelVisible, setSpinWheelVisible] = useState(false);
  const [isScratchCardVisible, setScratchCardVisible] = useState(false);
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    const fetchDeals = async () => {
      const response = await fetch('/api/deals'); // Your API endpoint
      const data = await response.json();
      setDeals(data);
    };

    fetchDeals();
  }, []);

  const handleSpinComplete = (result) => {
    alert(`You spun: ${result}`);
    setSpinWheelVisible(false);
  };

  const handleReveal = (result) => {
    alert(`You revealed: ${result}`);
    setScratchCardVisible(false);
  };

  const toggleSpinWheel = () => {
    setScratchCardVisible(false);
    setSpinWheelVisible((prev) => !prev);
  };

  const toggleScratchCard = () => {
    setSpinWheelVisible(false);
    setScratchCardVisible((prev) => !prev);
  };

  const loadingStyle = {
    animation: 'loadingAnimation 1s infinite',
    opacity: 0.5,
    textAlign: 'center',
  };

  return (
    <div className="min-h-screen bg-gray-200 py-8">
      <div className="max-w-3xl mx-auto bg-gray-50 rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-center mt-6 mb-4">Upcoming Deals & Offers</h2>
        <p className="text-lg text-center mb-4">Don't miss out on our latest deals!</p>

        {/* Deals List */}
        <div className="mb-4">
          {/* <h3 className="text-lg font-semibold mb-2">Deals List</h3> */}
          <ul className="mt-2">
            {deals.length > 0 ? (
              deals.map((deal, index) => (
                <li key={index} className="text-lg mb-1">{deal}</li>
              ))
            ) : (
              <li style={loadingStyle} className="text-lg">Loading deals...</li>
            )}
          </ul>
        </div>

        {/* Bottom Left Section for Spin Wheel and Scratch Card */}
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          {/* Spin Wheel Card */}
          <div className="flex-1 w-full md:w-1/2">
            <div className="bg-white rounded-lg shadow-md p-4">
              <h4 className="text-lg font-semibold mb-2">Spin the Wheel</h4>
              <button 
                className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-white font-bold rounded-lg px-6 py-2 shadow-lg mb-2 hover:from-yellow-500 hover:to-yellow-700 transition duration-300"
                onClick={toggleSpinWheel}
              >
                {isSpinWheelVisible ? 'Hide Spin Wheel' : 'Show Spin Wheel'}
              </button>
              {isSpinWheelVisible && <SpinWheel onSpinComplete={handleSpinComplete} />}
            </div>
          </div>

          {/* Scratch Card Card */}
          <div className="flex-1 w-full md:w-1/2">
            <div className="bg-white rounded-lg shadow-md p-4">
              <h4 className="text-lg font-semibold mb-2">Scratch Card</h4>
              <button 
                className="bg-gradient-to-r from-green-400 to-green-600 text-white font-bold rounded-lg px-6 py-2 shadow-lg mb-2 hover:from-green-500 hover:to-green-700 transition duration-300"
                onClick={toggleScratchCard}
              >
                {isScratchCardVisible ? 'Hide Scratch Card' : 'Show Scratch Card'}
              </button>
              {isScratchCardVisible && <ScratchCard onReveal={handleReveal} />}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default DealsPage;
