import React, { useEffect, useState } from 'react'
import SummaryApi from '../common'
import { toast } from 'react-toastify'
import moment from 'moment'
import { MdModeEdit } from "react-icons/md";
import ChangeUserRole from '../components/ChangeUserRole';

const AllUsers = () => {
  const [allUser, setAllUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([]) // State for filtered users
  const [searchTerm, setSearchTerm] = useState('') // State for search input
  const [openUpdateRole, setOpenUpdateRole] = useState(false)
  const [updateUserDetails, setUpdateUserDetails] = useState({
    email: "",
    name: "",
    role: "",
    _id: ""
  })

  // Fetch all users from API
  const fetchAllUsers = async () => {
    const fetchData = await fetch(SummaryApi.allUser.url, {
      method: SummaryApi.allUser.method,
      credentials: 'include'
    })

    const dataResponse = await fetchData.json()

    if (dataResponse.success) {
      setAllUsers(dataResponse.data)
      setFilteredUsers(dataResponse.data) // Initially set filtered users to all users
    }

    if (dataResponse.error) {
      toast.error(dataResponse.message)
    }
  }

  // Filter users whenever search term changes
  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredUsers(allUser)
    } else {
      const filtered = allUser.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilteredUsers(filtered)
    }
  }, [searchTerm, allUser])

  useEffect(() => {
    fetchAllUsers()
  }, [])

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-800">User Management</h1>
        <button className="bg-black text-white py-2 px-4 rounded-lg">+ Add User</button>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="px-6 py-4 border-b border-gray-200">
          <input 
            type="text" 
            placeholder="Search users..." 
            className="border border-gray-300 rounded-full py-2 px-4 w-full"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm state
          />
        </div>

        <table className="min-w-full leading-normal">
          <thead>
            <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Email</th>
              <th className="py-3 px-6 text-left">Role</th>
              <th className="py-3 px-6 text-left">Created Date</th>
              <th className="py-3 px-6 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((el, index) => (
                <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="py-3 px-6 text-left">
                    <div className="flex items-center">
                      <img 
                        className="w-10 h-10 rounded-full mr-4" 
                        src={el?.profilePic || 'https://via.placeholder.com/40'} 
                        alt="User Avatar" 
                      />
                      <span>{el?.name}</span>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <span>{el?.email}</span>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <span className={`py-1 px-3 rounded-full text-xs ${el.role === 'Admin' ? 'bg-green-100 text-green-700' : 'bg-blue-100 text-blue-700'}`}>
                      {el?.role}
                    </span>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <span>{moment(el?.createdAt).format('LL')}</span>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <button 
                      className="bg-red-500 text-white p-2 rounded-full hover:bg-blue-600"
                      onClick={() => {
                        setUpdateUserDetails(el)
                        setOpenUpdateRole(true)
                      }}
                    >
                      <MdModeEdit />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="py-3 px-6 text-center">No users found</td>
              </tr>
            )}
          </tbody>
        </table>

        {openUpdateRole && (
          <ChangeUserRole
            onClose={() => setOpenUpdateRole(false)}
            name={updateUserDetails.name}
            email={updateUserDetails.email}
            role={updateUserDetails.role}
            userId={updateUserDetails._id}
            callFunc={fetchAllUsers}
          />
        )}
      </div>
    </div>
  )
}

export default AllUsers
