// ConfirmationModal.jsx
import React from 'react';

const ConfirmationModal = ({ message, onClose, onConfirm }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                <p className="mb-4">{message}</p>
                <div className="flex justify-center space-x-4"> {/* Adjusted to use space-x-4 */}
                    <button 
                        onClick={onConfirm} 
                        className="bg-red-600 text-white py-2 px-4 rounded"
                    >
                        Yes
                    </button>
                    <button 
                        onClick={onClose} 
                        className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
