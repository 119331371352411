import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import image1 from '../assest/banner/img1.webp';
import image2 from '../assest/banner/img2.webp';
import image3 from '../assest/banner/img3.jpg';
import image4 from '../assest/banner/img4.jpg';
import image5 from '../assest/banner/img5.webp';
import image1Mobile from '../assest/banner/img1_mobile.jpg';
import image2Mobile from '../assest/banner/img2_mobile.webp';
import image3Mobile from '../assest/banner/img3_mobile.jpg';
import image4Mobile from '../assest/banner/img4_mobile.jpg';
import image5Mobile from '../assest/banner/img5_mobile.png';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";

const BannerProduct = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const navigate = useNavigate(); // Initialize useNavigate

    const desktopImages = [image1, image2, image3, image4, image5];
    const mobileImages = [image1Mobile, image2Mobile, image3Mobile, image4Mobile, image5Mobile];

    const nextImage = () => {
        if (currentImage < desktopImages.length - 1) {
            setCurrentImage(prev => prev + 1);
        }
    };

    const prevImage = () => {
        if (currentImage !== 0) {
            setCurrentImage(prev => prev - 1);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentImage < desktopImages.length - 1) {
                nextImage();
            } else {
                setCurrentImage(0);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [currentImage]);

    const handleImageClick = () => {
        console.log("Banner clicked!"); // Debug log
        navigate('/cart'); // Navigate to the cart page
    };

    return (
        <div className='w-screen mx-auto overflow-hidden relative'>
            <div className='h-56 md:h-72 w-full bg-slate-200 relative'>
                {/* Navigation buttons */}
                <div className='absolute z-10 h-full w-full md:flex items-center hidden'>
                    <div className='flex justify-between w-full text-2xl'>
                        <button onClick={prevImage} className='bg-white shadow-md rounded-full p-1'><FaAngleLeft /></button>
                        <button onClick={nextImage} className='bg-white shadow-md rounded-full p-1'><FaAngleRight /></button>
                    </div>
                </div>

                {/* Desktop version */}
                <div className='hidden md:flex h-full w-full overflow-hidden'>
                    {desktopImages.map((imageURL, index) => (
                        <div
                            className='w-full h-full min-w-full min-h-full transition-all duration-700 ease-in-out'
                            key={index} // Use index as key
                            style={{ transform: `translateX(-${currentImage * 100}%)` }}
                        >
                            <div onClick={handleImageClick} className='w-full h-full cursor-pointer'> {/* Wrap image in a clickable div */}
                                <img src={imageURL} className='w-full h-full object-cover' />
                            </div>
                        </div>
                    ))}
                </div>

                {/* Mobile version */}
                <div className='flex md:hidden h-full w-full overflow-hidden'>
                    {mobileImages.map((imageURL, index) => (
                        <div
                            className='w-full h-full min-w-full min-h-full transition-all duration-700 ease-in-out'
                            key={index} // Use index as key
                            style={{ transform: `translateX(-${currentImage * 100}%)` }}
                        >
                            <div onClick={handleImageClick} className='w-full h-full cursor-pointer'> {/* Wrap image in a clickable div */}
                                <img src={imageURL} className='w-full h-full object-cover' />
                            </div>
                        </div>
                    ))}
                </div>

                {/* Dot Indicators */}
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                    {desktopImages.map((_, index) => (
                        <div
                            key={index}
                            className={`h-2 w-2 rounded-full ${index === currentImage ? 'bg-blue-500' : 'bg-gray-300'}`}
                            onClick={() => setCurrentImage(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BannerProduct;
