import logo from './logo.svg';
import './App.css';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import NotFoundPage from './components/NotFoundPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import SummaryApi from './common';
import Context from './context';
import { useDispatch } from 'react-redux';
import { setUserDetails } from './store/userSlice';
import Maintenance from '../src/pages/maintenance'; // Import the Maintenance component

function App() {
  const dispatch = useDispatch();
  const [cartProductCount, setCartProductCount] = useState(0);
  
  // Use the environment variable for maintenance mode
  const isMaintenanceMode =  process.env.REACT_APP_MAINTENANCE_MODE === 'true'; 

  const fetchUserDetails = async () => {
    const dataResponse = await fetch(SummaryApi.current_user.url, {
      method: SummaryApi.current_user.method,
      credentials: 'include',
    });

    const dataApi = await dataResponse.json();

    if (dataApi.success) {
      dispatch(setUserDetails(dataApi.data));
    }
  };

  const fetchUserAddToCart = async () => {
    const dataResponse = await fetch(SummaryApi.addToCartProductCount.url, {
      method: SummaryApi.addToCartProductCount.method,
      credentials: 'include',
    });

    const dataApi = await dataResponse.json();

    setCartProductCount(dataApi?.data?.count);
  };

  useEffect(() => {
    /** User Details */
    fetchUserDetails();
    /** User Cart Product Count */
    fetchUserAddToCart();

    // Disable right-click context menu
    const disableContextMenu = (e) => {
      e.preventDefault();
    };
    document.onkeydown = function(e) {
      if (e.keyCode == 123 || // F12
          (e.ctrlKey && e.shiftKey && e.keyCode == 73) || // Ctrl+Shift+I
          (e.ctrlKey && e.keyCode == 85)) { // Ctrl+U
        return false;
      }
    };

    document.addEventListener('contextmenu', disableContextMenu);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
    };
    
  }, 
  
  []);

  return (
    <>
      <Context.Provider
        value={{
          fetchUserDetails, // user detail fetch
          cartProductCount, // current user add to cart product count
          fetchUserAddToCart,
        }}
      >
        <ToastContainer position="top-center" />

        <Header />
        
        <main className="min-h-[calc(100vh-120px)] pt-16">
          {isMaintenanceMode ? (
            <Maintenance /> // Show Maintenance page if in maintenance mode
          ) : (
            <Outlet /> // Show regular content otherwise
          )}
        </main>
        
        <Footer />
      </Context.Provider>
    </>
  );
}

export default App;
