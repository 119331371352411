import React, { useEffect, useState } from 'react';
import UploadProduct from '../components/UploadProduct';
import SummaryApi from '../common';
import AdminProductCard from '../components/AdminProductCard';

const AllProducts = () => {
  const [openUploadProduct, setOpenUploadProduct] = useState(false);
  const [allProduct, setAllProduct] = useState([]);

  const fetchAllProduct = async () => {
    try {
      const response = await fetch(SummaryApi.allProduct.url);
      const dataResponse = await response.json();

      console.log("API Response:", dataResponse);

      if (dataResponse.success) {
        setAllProduct(dataResponse.data || []);
      } else {
        console.error(dataResponse.message);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  return (
    <div className='min-h-screen bg-gray-100 p-6'>
      <div className='bg-white rounded-lg shadow-lg p-4 flex justify-between items-center mb-4'>
        <h2 className='font-bold text-xl'>All Products</h2>
        <button
          className='bg-red-600 text-white hover:bg-red-500 transition-all py-2 px-4 rounded-lg'
          onClick={() => setOpenUploadProduct(true)}
        >
          Upload Product
        </button>
      </div>

      {/* All Products */}
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
        {allProduct.length > 0 ? (
          allProduct.map((product, index) => (
            <AdminProductCard data={product} key={index + "allProduct"} fetchdata={fetchAllProduct} />
          ))
        ) : (
          <div className='col-span-full text-center'>
            <p className="text-gray-500">No products found.</p>
          </div>
        )}
      </div>

      {/* Upload Product Component */}
      {openUploadProduct && (
        <UploadProduct onClose={() => setOpenUploadProduct(false)} fetchData={fetchAllProduct} />
      )}
    </div>
  );
}

export default AllProducts;
