import React, { useState } from 'react';
import loginIcons from '../assest/signin.gif';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import imageTobase64 from '../helpers/imageTobase64';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import './Login.css'; // Import the CSS file

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    name: "",
    confirmPassword: "",
    profilePic: "",
  });
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUploadPic = async (e) => {
    const file = e.target.files[0];
    const imagePic = await imageTobase64(file);
    setData(prev => ({
      ...prev,
      profilePic: imagePic
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (data.password === data.confirmPassword) {
      const dataResponse = await fetch(SummaryApi.signUP.url, {
        method: SummaryApi.signUP.method,
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      const dataApi = await dataResponse.json();

      if (dataApi.success) {
        toast.success(dataApi.message);
        navigate("/login");
      } else if (dataApi.error) {
        toast.error(dataApi.message);
      }
    } else {
      toast.error("Please check password and confirm password");
    }
  };

  return (
    <section id='signup'>
      <div className='login-container'>
        <div className='login-message'>
          <h1>Create Your Account</h1>
          <p>Join us to explore more features.</p>
        </div>
        <div className='signup-box'>
          <div className='login-logo relative'>
            <img src={data.profilePic || loginIcons} alt='profile' />
            <label className='absolute top-2 right-2'>
              <div className='text-xs bg-opacity-80 bg-slate-200 pb-2 pt-1 cursor-pointer text-center'>
                Upload Photo
              </div>
              <input type='file' className='hidden' onChange={handleUploadPic} />
            </label>
          </div>
          <form className='login-form' onSubmit={handleSubmit}>
            <div className='form-group'>
              <label>Name:</label>
              <input
                type='text'
                placeholder='Enter your name'
                name='name'
                value={data.name}
                onChange={handleOnChange}
                required
              />
            </div>
            <div className='form-group'>
              <label>Email:</label>
              <input
                type='email'
                placeholder='Enter your email'
                name='email'
                value={data.email}
                onChange={handleOnChange}
                required
              />
            </div>
            <div className='form-group'>
              <label>Password:</label>
              <div className='password-container'>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder='Enter your password'
                  name='password'
                  value={data.password}
                  onChange={handleOnChange}
                  required
                />
                <div className='password-toggle' onClick={() => setShowPassword(prev => !prev)}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label>Confirm Password:</label>
              <div className='password-container'>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder='Confirm your password'
                  name='confirmPassword'
                  value={data.confirmPassword}
                  onChange={handleOnChange}
                  required
                />
                <div className='password-toggle' onClick={() => setShowConfirmPassword(prev => !prev)}>
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <button type='submit' className='login-button'>
              Sign Up
            </button>
            <div className='create-account'>
              <p>Already have an account?</p>
              <Link to="/login" className='signup-link'>
                Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
