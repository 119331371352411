import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import Header from './Header'; // Import the Header component
import './NotFoundPage.css'; 

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Header /> {/* Display Header */}
      <div className="not-found-container"> {/* Removed mt-10 class */}
        <img className='not-found-gif' src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif" alt="Not Found GIF"/>
        <h1 className="not-found-header">404</h1>
        <p className="not-found-text">Oops! The page you're looking for doesn't exist.</p>
        <button className="not-found-button" onClick={() => navigate('/')}>
          Go Home
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
