import React, { useState, useEffect } from 'react';
import Modal from './Modal'; // Import the custom modal
import './SpinWheel.css';

const SpinWheel = () => {
  const [spinning, setSpinning] = useState(false);
  const [result, setResult] = useState(null);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [redeemCode, setRedeemCode] = useState('');
  const [hasSpun, setHasSpun] = useState(false); // Track if user has spun today
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false); // Control modal visibility

  // Define the rewards in an array
  const rewards = [
    "10% off",
    "5% off",
    "50% off",
    "Better luck next time",
    "10 Rs",
    "1 Rs"
  ];

  // Check if the user has already spun today
  useEffect(() => {
    const lastSpinDate = localStorage.getItem('lastSpinDate');
    const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    if (lastSpinDate === today) {
      setHasSpun(true); // User has already spun today
    }
  }, []);

  const handleSpin = () => {
    // Prevent spinning if already spun today
    if (hasSpun) {
      setModalMessage('You can only spin once per day come back tomorrow!'); // Inform the user
      setShowModal(true);
      return;
    }

    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
      const randomIndex = Math.floor(Math.random() * rewards.length);
      const spinResult = rewards[randomIndex];
      setResult(spinResult);
      generateMathQuestion(); // Generate and display the math question
      setHasSpun(true); // Set that user has spun
      localStorage.setItem('lastSpinDate', new Date().toISOString().slice(0, 10)); // Save the date of the last spin
    }, 3000); // Duration of spin
  };

  const generateMathQuestion = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    const correctAnswer = num1 + num2; // Simple addition question
    setAnswer(correctAnswer); // Store the answer for validation
    setQuestion(`What is ${num1} + ${num2}?`); // Set the question
  };

  const handleAnswerSubmit = () => {
    if (parseInt(userAnswer) === answer) {
      const code = `REDEEM${Math.floor(1000 + Math.random() * 9000)}`; // Generate a random redeem code
      setRedeemCode(code);
      setModalMessage(`Correct! Your redeem code is: ${code}`); // Display success message
      setShowModal(true);
      // Reset question and answer
      setQuestion('');
      setUserAnswer('');
    } else {
      setModalMessage('Incorrect answer. Try again!'); // Display error message
      setShowModal(true);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(redeemCode);
    setModalMessage('Redeem code copied to clipboard!'); // Display copy confirmation message
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage(''); // Clear the message when closing the modal
  };

  return (
    <div className="spin-wheel-container">
      {showModal && <Modal message={modalMessage} onClose={closeModal} />}
      <div style={{ margin: '20px 0' }}> {/* Added margin for spacing */}
        <div className={`wheel ${spinning ? 'spin' : ''}`} onClick={handleSpin}>
          {rewards.map((reward, index) => (
            <div key={index} className="slice">{reward}</div>
          ))}
        </div>
      </div>
      {result && (
        <div className="result">
          <h2>You won: {result}!</h2> {/* Display the reward */}
        </div>
      )}
      {question && (
        <div className="mt-6 text-center">
          <p className="text-lg">{question}</p>
          <input 
            type="number" 
            value={userAnswer} 
            onChange={(e) => setUserAnswer(e.target.value)} 
            className="mt-2 p-2 border rounded"
            placeholder="Your answer"
          />
          <button 
            onClick={handleAnswerSubmit}
            className="mt-2 bg-gradient-to-r from-blue-400 to-blue-600 text-white font-bold rounded-lg px-4 py-2 transition duration-300"
          >
            Submit Answer
          </button>
        </div>
      )}
      {redeemCode && (
        <div className="mt-6 text-center">
          <p className="text-lg font-bold">Your redeem code is: {redeemCode}</p>
          <button 
            onClick={handleCopy}
            className="mt-2 bg-gradient-to-r from-green-400 to-green-600 text-white font-bold rounded-lg px-4 py-2 transition duration-300"
          >
            Copy Code
          </button>
        </div>
      )}
    </div>
  );
};

export default SpinWheel;
