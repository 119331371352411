import React, { useContext, useEffect, useState } from 'react';
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct';
import displayINRCurrency from '../helpers/displayCurrency';
import { Link } from 'react-router-dom';
import addToCart from '../helpers/addToCart';
import Context from '../context';
import { FaShoppingCart } from 'react-icons/fa'; // Import cart icon

const FlipkartDealCard = ({ category, heading }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const loadingList = new Array(10).fill(null);
    const { fetchUserAddToCart } = useContext(Context);

    const handleAddToCart = async (e, id) => {
        await addToCart(e, id);
        fetchUserAddToCart();
    };

    const fetchData = async () => {
        setLoading(true);
        const categoryProduct = await fetchCategoryWiseProduct(category);
        setLoading(false);
        setData(categoryProduct?.data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='container mx-auto px-4 my-8'>
            <h2 className='text-3xl font-semibold mb-6'>{heading}</h2>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4'>
                {loading ? (
                    loadingList.map((_, index) => (
                        <div key={index} className='bg-gray-200 rounded-lg shadow-lg p-4'>
                            <div className='bg-slate-300 h-32 animate-pulse'></div>
                            <div className='mt-4'>
                                <h3 className='font-medium text-lg animate-pulse bg-slate-300 h-6'></h3>
                                <p className='text-gray-500 animate-pulse bg-slate-300 h-4 mt-2'></p>
                                <div className='flex gap-3 mt-2'>
                                    <p className='text-red-600 font-medium animate-pulse bg-slate-300 h-6 w-1/2'></p>
                                    <p className='text-slate-500 line-through animate-pulse bg-slate-300 h-6 w-1/2'></p>
                                </div>
                                <button className='text-sm bg-slate-300 text-white px-3 rounded-full py-2 animate-pulse mt-2'></button>
                            </div>
                        </div>
                    ))
                ) : (
                    data.map((product) => (
                        <div key={product?._id} className='relative bg-white rounded-lg shadow-lg overflow-hidden'>
                            <Link to={`product/${product?._id}`}>
                                <img src={product.productImage[0]} alt={product.productName} className='w-full h-48 object-cover' />
                                <div className='p-4'>
                                    <h3 className='font-semibold text-lg'>{product?.productName}</h3>
                                    <p className='text-gray-700'>{product?.category}</p>
                                    <div className='flex gap-2 items-center'>
                                        <p className='text-red-600 font-semibold text-lg'>just {displayINRCurrency(product?.sellingPrice)}</p>
                                        <p className='text-slate-500 line-through'>{displayINRCurrency(product?.price)}</p>
                                    </div>
                                </div>
                            </Link>
                            <button
                                className='absolute bottom-2 right-2 bg-red-600 text-white py-1 px-2 rounded-full text-xs flex items-center'
                                onClick={(e) => handleAddToCart(e, product?._id)}
                            >
                                <FaShoppingCart className='mr-1' /> Add to Cart
                            </button>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default FlipkartDealCard;
