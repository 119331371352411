// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import CategoryList from '../components/CategoryList';
import BannerProduct from '../components/BannerProduct';
import HorizontalCardProduct from '../components/HorizontalCardProduct';
import VerticalCardProduct from '../components/VerticalCardProduct';
import MixedProducts from '../components/MixedProducts'; // Import the new component
import RedThemeCardProduct from '../components/RedThemeCardProduct';
import FeaturedBrands from '../components/FeaturedBrands';

import './wobble.css';
import './Home.css';

const Home = () => {
  return (
    <div>
      <CategoryList />
      <BannerProduct />
      <RedThemeCardProduct category={"Mixed"} heading={"Trending Products"} />
      <HorizontalCardProduct category={"airpodes"} heading={"Top's Airpodes"} />
      <HorizontalCardProduct category={"watches"} heading={"Popular's Watches"} />
      <VerticalCardProduct category={"T-shirt"} heading={"T-shirt"} />
      <VerticalCardProduct category={"mobiles"} heading={"Mobiles"} />
      <VerticalCardProduct category={"Mouse"} heading={"Mouse"} />
      
      {/* <FeaturedBrands /> */}
      <VerticalCardProduct category={"camera"} heading={"Camera & Photography"} />
      <VerticalCardProduct category={"televisions"} heading={"Televisions"} />
      
     

      {/* Add MixedProducts component */}
      <MixedProducts  heading={"Mixed Products"} />

      {/* Link to the Deals Page */}
      <Link 
        to="/deals"
        className='fixed bottom-4 right-4 bg-red-600 text-white rounded-full p-3 wobble'
      >
        Deals & Offers
      </Link>
    </div>
  );
}

export default Home;
