// ConfirmationPage.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa'; // Importing a check circle icon for a modern look
import '../App.css';

const ConfirmationPage = () => {
    const navigate = useNavigate();

    const handleContinueShopping = () => {
        navigate('/'); // Redirect to the homepage or any desired page
    };

    return (
        <div className="container mx-auto flex flex-col items-center justify-center min-h-screen p-6 bg-gray-100">
            <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md text-center">
                <div className="flex items-center justify-center mb-4">
                    <FaCheckCircle className="text-green-500 text-6xl animate-bounce" />
                </div>
                <h1 className="text-3xl font-bold mb-2">Thank You for Your Purchase!</h1>
                <p className="text-lg text-gray-600 mb-6">Your order has been successfully processed.</p>
                <button 
                    onClick={handleContinueShopping}
                    className="bg-red-600 text-white py-3 px-6 rounded-md shadow hover:bg-blue-700 transition-all duration-300"
                >
                    Continue Shopping
                </button>
            </div>
        </div>
    );
};

export default ConfirmationPage;