// src/pages/ProfileDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ProfileDetail = () => {
  const { id } = useParams(); // Get the ID from the URL
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Fetch user data based on the ID
    const fetchUserData = async () => {
      const response = await fetch(`REACT_APP_FRONT_API_URL/api/users/${id}`);
      const data = await response.json();
      setUserData(data);
    };

    fetchUserData();
  }, [id]);

  if (!userData) {
    return 
    // <div>Loading...</div>
    ;
  }

  return (
    <div>
      <h2>{userData.name}</h2>
      <p>Email: {userData.email}</p>
      {/* Display other user details here */}
    </div>
  );
};

export default ProfileDetail;
