import React, { useState } from 'react';
import { FaMapMarkerAlt, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const OrderDetails = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false);
    const [areDetailsVisible, setAreDetailsVisible] = useState(false); // New state for order details visibility
    
    // Dummy order data for demonstration
    const order = {
        orderId: '123456',
        date: '2024-09-26',
        items: [
            { id: 1, name: 'Product 1', quantity: 2, price: 29.99 },
            { id: 2, name: 'Product 2', quantity: 1, price: 49.99 },
        ],
        total: 109.97,
        status: 'In Transit',
        location: '123 Main St, City, State',
        trackingSteps: [
            { id: 1, status: 'Order Placed', date: '2024-09-26', completed: true },
            { id: 2, status: 'Shipped', date: '2024-09-27', completed: true },
            { id: 3, status: 'Out for Delivery', date: '2024-09-28', completed: false },
            { id: 4, status: 'Delivered', date: '', completed: false },
        ],
    };

    const handleCancelOrder = () => {
        setIsConfirmPopupVisible(true); // Show the confirmation popup
    };

    const confirmCancelOrder = () => {
        // Implement the cancel order logic here
        setIsConfirmPopupVisible(false);
        setIsPopupVisible(true); // Show the cancellation success popup
    };

    const closePopup = () => {
        setIsPopupVisible(false);
    };

    const closeConfirmPopup = () => {
        setIsConfirmPopupVisible(false);
    };

    const toggleDetailsVisibility = () => {
        setAreDetailsVisible(prev => !prev); // Toggle the visibility of order details
    };

    return (
        <div className='max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg'>
            <h2 className='text-2xl font-bold mb-4'>Order Details</h2>

            {/* Displaying Ordered Products */}
            <h3 className='text-lg font-semibold mt-4'>Ordered Products</h3>
            <ul className='divide-y divide-gray-200'>
                {order.items.map(item => (
                    <li key={item.id} className='flex justify-between py-2'>
                        <span>{item.name} (x{item.quantity})</span>
                        <span>${item.price.toFixed(2)}</span>
                    </li>
                ))}
            </ul>

            {/* View Details Button */}
            <div className='mt-4 flex justify-center'>
                <button
                    onClick={toggleDetailsVisibility}
                    className='px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition-transform transform hover:scale-105'
                >
                    {areDetailsVisible ? 'Hide Details' : 'View Details'}
                </button>
            </div>

            {/* Conditional Rendering of Order Details */}
            {areDetailsVisible && (
                <>
                    <div className='mt-6'>
                        <div className='mb-4'>
                            <p className='font-medium'>Order ID:</p>
                            <p>{order.orderId}</p>
                        </div>
                        <div className='mb-4'>
                            <p className='font-medium'>Order Date:</p>
                            <p>{order.date}</p>
                        </div>
                        <div className='mb-4'>
                            <p className='font-medium'>Status:</p>
                            <p className={`flex items-center ${order.status === 'Delivered' ? 'text-green-500' : 'text-orange-500'}`}>
                                {order.status === 'Delivered' ? <FaCheckCircle /> : <FaTimesCircle />} {order.status}
                            </p>
                        </div>
                        <div className='mb-4'>
                            <p className='font-medium'>Delivery Location:</p>
                            <div className='flex items-center'>
                                <FaMapMarkerAlt className='text-red-500 mr-2' />
                                <p>{order.location}</p>
                            </div>
                        </div>

                        <h3 className='text-lg font-semibold mt-4'>Tracking</h3>
                        <ul className='divide-y divide-gray-200'>
                            {order.trackingSteps.map(step => (
                                <li key={step.id} className='flex justify-between py-2'>
                                    <div className='flex items-center'>
                                        {step.completed ? (
                                            <FaCheckCircle className='text-green-500 mr-2' />
                                        ) : (
                                            <FaTimesCircle className='text-gray-500 mr-2' />
                                        )}
                                        <span>{step.status}</span>
                                    </div>
                                    <span>{step.date || 'Pending'}</span>
                                </li>
                            ))}
                        </ul>

                        {/* Total Amount */}
                        <div className='mt-4'>
                            <p className='font-semibold'>Total: ${order.total.toFixed(2)}</p>
                        </div>

                        {/* Cancel Order Button */}
                        <div className='mt-6 flex justify-center'>
                            <button
                                onClick={handleCancelOrder}
                                className='px-4 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 transition-transform transform hover:scale-105'
                            >
                                Cancel Order
                            </button>
                        </div>
                    </div>
                </>
            )}

            {/* Confirmation Popup */}
            {isConfirmPopupVisible && (
                <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
                    <div className='bg-white p-6 rounded-lg shadow-lg text-center'>
                        <h3 className='text-lg font-bold mb-4'>Are you sure?</h3>
                        <p>Do you really want to cancel your order?</p>
                        <div className='mt-4 flex justify-center'>
                            <button
                                onClick={confirmCancelOrder}
                                className='px-4 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 transition-transform transform hover:scale-105 mr-2'
                            >
                                Yes
                            </button>
                            <button
                                onClick={closeConfirmPopup}
                                className='px-4 py-2 bg-gray-300 text-black rounded-lg shadow-md hover:bg-gray-400 transition-transform transform hover:scale-105'
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Popup Notification */}
            {isPopupVisible && (
                <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
                    <div className='bg-white p-6 rounded-lg shadow-lg text-center'>
                        <h3 className='text-lg font-bold mb-4'>Order Canceled</h3>
                        <p>Your order has been successfully canceled.</p>
                        <button
                            onClick={closePopup}
                            className='mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition-transform transform hover:scale-105'
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderDetails;
