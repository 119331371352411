const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME_CLOUDINARY}/image/upload`


const uploadImage = async (image) => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "mern_product");

    try {
        const dataResponse = await fetch(url, {
            method: "POST",
            body: formData,
        });

        const result = await dataResponse.json();
        if (!dataResponse.ok) {
            console.error('Error uploading image:', result); // Log Cloudinary's error response
            return;
        }
        console.log('Image uploaded successfully:', result);
        return result;
    } catch (error) {
        console.error('Fetch error:', error);
    }
};


export default uploadImage 

