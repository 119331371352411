import React from 'react';
import './Modal.css'; // Import your updated custom styles

const Modal = ({ message, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-body">
          <p>{message}</p>
        </div>
        <div className="modal-footer">
          <button onClick={onClose} className="confirm-button">Close</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
