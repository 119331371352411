// src/components/MixedProducts.js
import React, { useEffect, useState } from 'react';
import fetchMixedProducts from '../helpers/fetchMixedProducts'; // Create this helper function
import displayINRCurrency from '../helpers/displayCurrency';
import { Link } from 'react-router-dom';
import './MixedProducts.css'; // Create a CSS file for styles

const MixedProducts = ({ heading }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        setLoading(true);
        const products = await fetchMixedProducts(); // Fetch mixed products from your API
        setData(products);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='mixed-products-container'>
            <h2 className='heading'>{heading}</h2>
            <div className='product-grid'>
                {loading ? (
                    <p>Loading products...</p>
                ) : (
                    data.map((product) => (
                        <Link key={product._id} to={`product/${product._id}`} className='product-card'>
                            <div className='product-image'>
                                <img src={product.productImage[0]} alt={product.productName} />
                            </div>
                            <div className='product-content'>
                                <h3 className='product-title'>{product.productName}</h3>
                                <p className='product-price'>{displayINRCurrency(product.sellingPrice)}</p>
                                {product.price && (
                                    <p className='original-price'>{displayINRCurrency(product.price)}</p>
                                )}
                            </div>
                        </Link>
                    ))
                )}
            </div>
        </div>
    );
};

export default MixedProducts;
