import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import displayINRCurrency from '../helpers/displayCurrency'; // Assuming you have this helper for currency formatting
import Modal from './PaymentModal'; // Import the Modal component
import ConfirmationModal from '../common/ConfirmationModal';

const Payment = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { totalAmount } = location.state || { totalAmount: 0 }; // Default to 0 if not passed
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [redeemCode, setRedeemCode] = useState('');
    const [discount, setDiscount] = useState(0);

    const handlePayment = async () => {
        setIsProcessing(true);

        // Simulating payment processing delay
        setTimeout(() => {
            setModalMessage("Payment was successful!"); // Set confirmation message
            setShowModal(true);
            setIsProcessing(false);
        }, 2000); // Simulate 2 seconds processing time
    };

    const handleCancel = () => {
        setShowCancelConfirmation(true); // Show the cancel confirmation modal
    };

    const handleConfirmCancel = () => {
        navigate('/cart'); // Change '/cart' to your actual cart route
    };

    const handleVerify = (method) => {
        setModalMessage(`Verifying ${method} details...`);
        setShowModal(true);
    };

    const handleRedeemCode = () => {
        // Example: Check if redeem code is valid and set discount
        if (redeemCode === 'DISCOUNT10') { // Change this to your actual logic
            setDiscount(10); // Set a discount amount, e.g., 10%
            alert("Redeem code applied successfully!");
        } else {
            alert("Invalid redeem code");
            setDiscount(0); // Reset discount if the code is invalid
        }
    };

    const renderPaymentInputs = () => {
        switch (paymentMethod) {
            case 'credit':
                return (
                    <div className='flex flex-col gap-2'>
                        <div className='flex items-center'>
                            <input
                                type='text'
                                placeholder='Card Number'
                                className='border p-2 rounded flex-grow'
                            />
                        </div>
                        <div className='flex items-center'>
                            <input
                                type='text'
                                placeholder='Expiry Date (MM/YY)'
                                className='border p-2 rounded flex-grow'
                            />
                        </div>
                        <div className='flex items-center'>
                            <input
                                type='text'
                                placeholder='CVV'
                                className='border p-2 rounded flex-grow'
                            />
                            <button onClick={() => handleVerify('CVV')} className='ml-2 bg-blue-600 text-white py-1 px-3 rounded'>Verify</button>
                        </div>
                    </div>
                );
            case 'paypal':
                return (
                    <div className='flex items-center'>
                        <input
                            type='email'
                            placeholder='PayPal Email'
                            className='border p-2 rounded flex-grow'
                        />
                        <button onClick={() => handleVerify('PayPal Email')} className='ml-2 bg-blue-600 text-white py-1 px-3 rounded'>Verify</button>
                    </div>
                );
            case 'upi':
                return (
                    <div className='flex items-center'>
                        <input
                            type='text'
                            placeholder='Enter UPI ID'
                            className='border p-2 rounded flex-grow'
                        />
                        <button onClick={() => handleVerify('UPI ID')} className='ml-2 bg-blue-600 text-white py-1 px-3 rounded'>Verify</button>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderModal = () => {
        if (showModal) {
            return (
                <Modal 
                    message={modalMessage} 
                    onClose={() => {
                        setShowModal(false);
                        if (modalMessage === "Payment was successful!") {
                            navigate('/confirmation'); // Navigate to confirmation page
                        }
                    }} 
                />
            );
        }

        if (showCancelConfirmation) {
            return (
                <ConfirmationModal 
                    message="Are you sure you want to cancel the payment?"
                    onClose={() => setShowCancelConfirmation(false)} // Close the modal
                    onConfirm={handleConfirmCancel} // Confirm cancellation
                    confirmText="Yes, Cancel"
                    cancelText="No, Go Back"
                />
            );
        }

        return null; // No modal to display
    };

    const finalAmount = totalAmount - (totalAmount * discount / 100); // Calculate final amount after discount

    return (
        <div className='container mx-auto p-6'>
            <div className='max-w-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden'>
                <div className='bg-blue-600 p-6'>
                    <h1 className='text-3xl text-white font-bold'>Payment Page</h1>
                    <p className='text-lg text-white mt-2'>Please review your order details below:</p>
                </div>

                <div className='p-6'>
                    <div className='flex justify-between mb-4'>
                        <p className='text-lg font-medium text-gray-700'>Total Amount:</p>
                        <p className='text-lg font-bold text-blue-600'>{displayINRCurrency(totalAmount)}</p>
                    </div>

                    {/* Discount Section */}
                    <div className='flex mb-4'>
                        <input 
                            type='text' 
                            placeholder='Enter Redeem Code'
                            value={redeemCode}
                            onChange={(e) => setRedeemCode(e.target.value)}
                            className='border p-2 rounded flex-grow'
                        />
                        <button onClick={handleRedeemCode} className='ml-2 bg-blue-600 text-white py-2 px-4 rounded'>Apply</button>
                    </div>

                    {/* Discount Display */}
                    {discount > 0 && (
                        <div className='flex justify-between mb-4'>
                            <p className='text-lg font-medium text-gray-700'>Discount Applied:</p>
                            <p className='text-lg font-bold text-red-600'>-{displayINRCurrency((totalAmount * discount / 100))}</p>
                        </div>
                    )}

                    {/* Final Amount Display */}
                    <div className='flex justify-between mb-4'>
                        <p className='text-lg font-medium text-gray-700'>Final Amount:</p>
                        <p className='text-lg font-bold text-green-600'>{displayINRCurrency(finalAmount)}</p>
                    </div>

                    {/* Payment Methods Section */}
                    <h2 className='text-xl font-semibold text-gray-800 mt-4'>Choose Payment Method</h2>
                    <div className='mt-2'>
                        <label className='block'>
                            <input 
                                type='radio' 
                                name='payment' 
                                value='credit' 
                                className='mr-2' 
                                onChange={() => setPaymentMethod('credit')} 
                            />
                            Credit/Debit Card
                        </label>
                        <label className='block'>
                            <input 
                                type='radio' 
                                name='payment' 
                                value='paypal' 
                                className='mr-2' 
                                onChange={() => setPaymentMethod('paypal')} 
                            />
                            PayPal
                        </label>
                        <label className='block'>
                            <input 
                                type='radio' 
                                name='payment' 
                                value='upi' 
                                className='mr-2' 
                                onChange={() => setPaymentMethod('upi')} 
                            />
                            UPI
                        </label>
                    </div>

                    {/* Render Input Fields Based on Selected Payment Method */}
                    {paymentMethod && (
                        <div className='mt-4'>
                            {renderPaymentInputs()}
                        </div>
                    )}

                    <div className='flex flex-col gap-4 mt-6'>
                        <button
                            onClick={handlePayment}
                            className='bg-red-600 text-white py-3 rounded-md shadow-md hover:bg-blue-700 transition-all'
                        >
                            {isProcessing ? 'Processing...' : 'Pay Now'}
                        </button>
                        <button 
                            onClick={handleCancel} 
                            className='bg-gray-200 text-gray-700 py-3 rounded-md shadow-md hover:bg-gray-300 transition-all'
                        >
                            Cancel
                        </button>
                    </div>

                    <div className='mt-4 text-gray-600 text-sm'>
                        <p>By proceeding, you agree to our Terms of Service and Privacy Policy.</p>
                    </div>
                </div>
            </div>

            {/* Render Modal */}
            {renderModal()}
        </div>
    );
};

export default Payment;
