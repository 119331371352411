// Modal.js
import React from 'react';
import '../App.css';

const Modal = ({ message, onClose, onVerify }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full transition-transform transform scale-95 hover:scale-100">
                <h2 className="text-2xl font-semibold text-gray-800">Verification Status</h2>
                {/* <p className="mt-2 text-gray-600">Verifying UPI status...</p> */}
                <p className="mt-2 text-gray-600 animated-text">{message}</p>
                <div className="flex justify-between mt-4">
                    <button 
                        className="bg-crimson text-white py-2 px-4 rounded-md transition-colors duration-300 hover:bg-crimson-dark"
                        onClick={onVerify}
                    >
                        Verify
                    </button>
                    <button 
                        className="bg-gray-200 text-gray-700 py-2 px-4 rounded-md transition-colors duration-300 hover:bg-gray-300"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
