import React, { useContext, useEffect, useState } from 'react';
import SummaryApi from '../common';
import Context from '../context';
import displayINRCurrency from '../helpers/displayCurrency';
import { MdDelete } from "react-icons/md";
import { FaHome, FaPhone, FaEdit } from "react-icons/fa"; 
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const context = useContext(Context);
    const navigate = useNavigate();

    const user = useSelector(state => state?.user?.user);
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        if (user) {
            setAddress(user.address || '');
            setPhone(user.phone || '');
        }
    }, [user]);

    const fetchData = async () => {
        const response = await fetch(SummaryApi.addToCartProductView.url, {
            method: SummaryApi.addToCartProductView.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            },
        });

        const responseData = await response.json();
        if (responseData.success) {
            setData(responseData.data);
        }
    };

    const handleLoading = async () => {
        await fetchData();
    };

    useEffect(() => {
        setLoading(true);
        handleLoading();
        setLoading(false);
    }, []);

    const updateQty = async (id, qty) => {
        if (qty < 1) return;

        const response = await fetch(SummaryApi.updateCartProduct.url, {
            method: SummaryApi.updateCartProduct.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            },
            body: JSON.stringify({ _id: id, quantity: qty })
        });

        const responseData = await response.json();
        if (responseData.success) {
            fetchData();
        }
    };

    const deleteCartProduct = async (id) => {
        const response = await fetch(SummaryApi.deleteCartProduct.url, {
            method: SummaryApi.deleteCartProduct.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            },
            body: JSON.stringify({ _id: id })
        });

        const responseData = await response.json();
        if (responseData.success) {
            fetchData();
            context.fetchUserAddToCart();
        }
    };

    const totalQty = data.reduce((prev, curr) => prev + curr.quantity, 0);
    const totalPrice = data.reduce((prev, curr) => prev + (curr.quantity * curr?.productId?.sellingPrice), 0);

    const handlePaymentClick = () => {
        navigate('/payment', { state: { totalAmount: totalPrice, deliveryAddress: address } });
    };

    const handleEditClick = () => {
        navigate('/profile'); // Change this to your actual update page path
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold text-center mb-4"></h1>
            {loading && <p className="text-center">Loading...</p>}
            {data.length === 0 && !loading && (
                <p className="text-center bg-gray-200 py-5 rounded-md">No Items in Cart</p>
            )}
            <div className="flex flex-col lg:flex-row lg:space-x-6">
                {/* Left Side: Cart Items */}
                <div className="flex-1 bg-white rounded-md shadow-md p-4">
                    {data.map((product) => (
                        <div key={product?._id} className="flex items-center justify-between border-b border-gray-300 py-4">
                            <img
                                src={product?.productId?.productImage[0]}
                                className="w-24 h-24 object-cover rounded-md"
                                alt={product?.productId?.productName}
                            />
                            <div className="flex-1 px-4">
                                <h2 className="text-lg font-semibold">{product?.productId?.productName}</h2>
                                <p className="text-sm text-gray-500">{product?.productId?.category}</p>
                                <p className="text-red-600 font-semibold">{displayINRCurrency(product?.productId?.sellingPrice)}</p>
                                <div className="flex items-center space-x-3 mt-2">
                                    <button onClick={() => updateQty(product?._id, product?.quantity - 1)} className="border border-gray-400 px-2 py-1 rounded-md text-lg">-</button>
                                    <span className="text-lg">{product?.quantity}</span>
                                    <button onClick={() => updateQty(product?._id, product?.quantity + 1)} className="border border-gray-400 px-2 py-1 rounded-md text-lg">+</button>
                                    <button onClick={() => deleteCartProduct(product?._id)} className="text-red-500 text-lg">
                                        <MdDelete />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Right Side: Delivery Information and Payment Summary */}
                <div className="w-full md:w-1/3 bg-white rounded-md shadow-md p-4 mt-4 lg:mt-0">
                    <h3 className="text-lg font-bold mb-3">Delivery Information</h3>
                    <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                        <FaHome className="mr-2 text-gray-500 text-xl md:text-3xl" /> {/* Increased size for better visibility */}
                        <span className="break-words max-w-full">{address || 'No address provided'}</span>
                    </div>
                    
                    <div className="flex items-center border-b border-gray-300 pb-2">
                        <FaPhone className="mr-2 text-gray-500 text-xl sm:text-xl" />
                        <span>{phone || 'No phone number provided'}</span>
                    </div>

                    <div className="flex items-center mt-3">
                        <FaEdit
                            className="text-blue-500 cursor-pointer ml-4 hover:underline"
                            onClick={handleEditClick}
                        />
                        <span className="ml-1 text-sm text-gray-500">Edit</span>
                    </div>
                    
                    <h3 className="text-lg font-bold mb-3 mt-4">Payment Summary</h3>
                    <div className="flex justify-between mb-2">
                        <span>Total Quantity:</span>
                        <span>{totalQty}</span>
                    </div>
                    <div className="flex justify-between mb-4">
                        <span>Total Price:</span>
                        <span className="text-red-600 font-semibold">{displayINRCurrency(totalPrice)}</span>
                    </div>
                    <button
                        className="w-full bg-blue-500 text-white p-3 mt-4 rounded-md hover:bg-blue-600 transition"
                        onClick={handlePaymentClick}
                    >
                        Proceed to Payment
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Cart;
