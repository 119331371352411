import React, { useState } from 'react';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(''); // Error state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(''); // Reset message
    setError(''); // Reset error
    setLoading(true); // Start loading

    try {
      const response = await fetch('/api/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage(`A password reset link has been sent to ${email}.`);
      } else {
        const data = await response.json();
        setError(data.message || 'Failed to send email. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false); // End loading
    }

    setEmail(''); // Clear the input field
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <div style={styles.inputGroup}>
          <label htmlFor="email" style={styles.label}>Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.button} disabled={loading}>
          {loading ? 'Sending...' : 'Send Reset Link'}
        </button>
      </form>
      {message && <p style={styles.message}>{message}</p>}
      {error && <p style={{ ...styles.message, color: 'red' }}>{error}</p>} {/* Display error message */}
    </div>
  );
};

// Inline styles
const styles = {
  container: {
    maxWidth: '400px',
    margin: '50px auto',
    padding: '20px',
    border: '1px solid #f44336', // Red border
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff', // White background
  },
  heading: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#f44336', // Red color for heading
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#f44336', // Red color for label
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #f44336', // Red border
    borderRadius: '4px',
    transition: 'border-color 0.3s',
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#f44336', // Red background
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  },
  message: {
    marginTop: '15px',
    color: 'green',
    textAlign: 'center',
  },
};

// Export the component
export default ForgotPassword;
