// src/helpers/fetchMixedProducts.js
import productCategory from './productCategory'; // Adjust the import path

const fetchMixedProducts = async () => {
    const selectedCategories = getRandomCategories(5); // Get 5 random categories
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mixed-products?categories=${selectedCategories.join(',')}`);
        const data = await response.json();
        return data.products; // Adjust this according to your API response
    } catch (error) {
        console.error('Error fetching mixed products:', error);
        return []; // Return an empty array on error
    }
};

// Function to get random categories
const getRandomCategories = (num) => {
    const shuffled = productCategory.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num).map(category => category.value);
};

export default fetchMixedProducts;
