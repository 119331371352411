import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className='bg-slate-800 text-white py-6 hidden lg:block'>
      <div className='container mx-auto px-4'>
        {/* Footer Content */}
        <div className='flex flex-col items-center'>
          <p className='text-center font-bold text-lg mb-4' title="Youtube Channel">SHOPIT.CO</p>
          
          {/* Social Media Links */}
          <div className='flex gap-4 mb-4'>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className='text-white hover:text-gray-400'>
              <FaFacebook size={24} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className='text-white hover:text-gray-400'>
              <FaTwitter size={24} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className='text-white hover:text-gray-400'>
              <FaInstagram size={24} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className='text-white hover:text-gray-400'>
              <FaLinkedin size={24} />
            </a>
          </div>

          {/* Additional Footer Links (Optional) */}
          <div className='text-center'>
            <a href="/privacy-policy" className='text-gray-300 hover:text-gray-100 mx-2'>Privacy Policy</a>
            <a href="/terms-of-service" className='text-gray-300 hover:text-gray-100 mx-2'>Terms of Service</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
