import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaRegCircleUser, FaPhone } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { Link, Outlet, useNavigate } from 'react-router-dom';

const UserProfile = () => {
    const user = useSelector(state => state?.user?.user);
    const navigate = useNavigate();
    const [address, setAddress] = useState(user?.address || '');
    const [phone, setPhone] = useState(user?.phone || '');
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [currentSection, setCurrentSection] = useState('profile');

    useEffect(() => {
        if (!user?._id) {
            navigate("/login");
        } else {
            setAddress(user.address || '');
            setPhone(user.phone || '');
        }
    }, [user, navigate]);

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };
    const token = localStorage.getItem('token');

if (!token) {
    setError('You need to be logged in to update your profile.');
    return;
}

    
    const handleLogin = async (credentials) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });
    
        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('token', data.token); // Ensure token is saved
            // Navigate to user profile or other actions...
        } else {
            // Handle login error
        }
    };
    

    const handleSave = async () => {
        setIsSaving(true);
        setError(null);
        setSuccess(null);
        
        try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/update-userprofile`;
    
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${token}`, // Remove this line
                },
                credentials: 'include', // Ensure cookies are included in the request
                body: JSON.stringify({ address, phone }),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText); // Log the error response
                throw new Error(`HTTP error! Status: ${response.status}. Response: ${errorText}`);
            }
    
            const data = await response.json();
    
            if (!data.success) {
                throw new Error(data.message);
            }
    
            setSuccess('Profile updated successfully');
        } catch (error) {
            setError(error.message);
        } finally {
            setIsSaving(false);
        }
    };
    

    const handleSectionChange = (section) => {
        setCurrentSection(section);
        navigate(`/profile/${section}`);
    };

    return (
        <div className='flex flex-col md:flex-row min-h-[calc(100vh-120px)]'>
            <aside className='bg-white min-h-full w-full md:max-w-60 customShadow p-4'>
                <div className='h-40 flex justify-center items-center flex-col'>
                    <div className='text-6xl relative'>
                        {
                            user?.profilePic ? (
                                <img src={user?.profilePic} className='w-24 h-24 rounded-full shadow-lg' alt={user?.name} />
                            ) : (
                                <FaRegCircleUser className="text-gray-500 shadow-md" />
                            )
                        }
                    </div>
                    <p className='capitalize text-lg font-bold mt-4'>{user?.name}</p>
                    <p className='text-sm text-gray-500'>{user?.email}</p>
                </div>

                <nav className='grid gap-2 mt-6'> 
                    <Link to="#" onClick={() => handleSectionChange('profile')} className='block hover:bg-gray-100 px-4 py-2 text-gray-800'>Profile</Link>
                    <Link to="#" onClick={() => handleSectionChange('order')} className='px-4 py-2 hover:bg-gray-100 rounded'>Orders</Link>
                    <Link to="#" onClick={() => handleSectionChange('settings')} className='px-4 py-2 hover:bg-gray-100 rounded'>Settings</Link>
                </nav>
            </aside>

            <main className='w-full p-6'>
                {currentSection === 'profile' && (
                    <div className='max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg'>
                        <h2 className='text-2xl font-bold mb-6'>Profile Information</h2>

                        {success && <p className='text-green-500'>{success}</p>}
                        {error && <p className='text-red-500'>{error}</p>}

                        <div className='mb-4'>
                            <label className='block text-sm font-medium mb-1'>Name</label>
                            <input
                                type='text'
                                value={user?.name}
                                readOnly
                                className='w-full border border-gray-300 rounded p-3 shadow-sm'
                            />
                        </div>

                        <div className='mb-4 relative'>
                            <label className='block text-sm font-medium mb-1'>Address</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                    <FaHome className="text-gray-500" />
                                </span>
                                <input
                                    type='text'
                                    value={address}
                                    onChange={handleAddressChange}
                                    className='w-full border border-gray-300 rounded p-3 pl-10 shadow-sm focus:ring focus:ring-blue-300'
                                    placeholder='Enter your address'
                                />
                            </div>
                        </div>

                        <div className='mb-4 relative'>
                            <label className='block text-sm font-medium mb-1'>Phone Number</label>
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                    <FaPhone className="text-gray-500" />
                                </span>
                                <input
                                    type='text'
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    className='w-full border border-gray-300 rounded p-3 pl-10 shadow-sm focus:ring focus:ring-blue-300'
                                    placeholder='Enter your phone number'
                                />
                            </div>
                        </div>

                        <button
                            onClick={handleSave}
                            className={`px-4 py-2 w-full text-white bg-blue-500 rounded shadow-md hover:bg-blue-600 transition-transform transform hover:scale-105 ${isSaving ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                )}
                
                {currentSection === 'order' && (
                    <div>
                        {/* Render Order Section Content Here */}
                        <Outlet />
                    </div>
                )}

                {currentSection === 'settings' && (
                    <div>
                        {/* Render Settings Section Content Here */}
                        <Outlet />
                    </div>
                )}
            </main>
        </div>
    );
}

export default UserProfile;
