// src/router/index.js
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import SignUp from '../pages/SignUp';
import AdminPanel from '../pages/AdminPanel';
import AllUsers from '../pages/AllUsers';
import AllProducts from '../pages/AllProducts';
import CategoryProduct from '../pages/CategoryProduct';
import ProductDetails from '../pages/ProductDetails';
import Cart from '../pages/Cart';
import SearchProduct from '../pages/SearchProduct';
import ErrorBoundary from '../components/NotFoundPage'; // Import the ErrorBoundary
import UserProfile from '../pages/UserProfile';
import ProfileDetail from '../components/ProfileDetail'; // Import the ProfileDetail component
import UserProfileDetails from '../pages/UserProfileDetails';
import DealsPage from '../pages/DealsPage'; // Import the DealsPage component
import Order from '../components/Order'; // Import the DealsPage component
import Settings from '../components/Settings';
import Payment from '../pages/Payment';
import ConfirmationPage from '../pages/ConfirmationPage';


const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />, // Add the error boundary here
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'sign-up',
        element: <SignUp />
      },
      {
        path: 'product-category',
        element: <CategoryProduct />
      },
      {
        path: 'product/:id',
        element: <ProductDetails />
      },
      {
        path: 'cart',
        element: <Cart />
      },
      {
        path: 'search',
        element: <SearchProduct />
      },
      {
        path: 'deals', // New route for DealsPage
        element: <DealsPage />
      },
      {
        path: 'admin-panel',
        element: <AdminPanel />,
        children: [
          {
            path: 'all-users',
            element: <AllUsers />
          },
          {
            path: 'all-products',
            element: <AllProducts />
          }
        ]
      },
      {
        path: 'profile',
        element: <UserProfile />,
        children: [
          {
            path: ':id', // Dynamic user ID
            element: <ProfileDetail /> // Component to handle dynamic profile details
          },
          {
            path: 'order',
            element: <Order /> // Capitalized component name
          },
          
          {
            path: 'Settings',
            element: < Settings/>
          }
        ]
      },
      {
        path :'/payment' ,
        element: <Payment />
      },
      {
        path :'/confirmation' ,
        element: <ConfirmationPage />
      }
    ]
  }
]);

export default router;
