import React, { useState, useEffect, useRef } from 'react';
import Modal from './Modal'; // Import your custom modal
import './ScratchCard.css';
import scratchCardImage from '../assest/scratch-card.jpg'; // Ensure the path is correct
import { ScratchCard as ScratchCardJS, SCRATCH_TYPE } from 'scratchcard-js';

const ScratchCard = () => {
  const [scratched, setScratched] = useState(false);
  const [result, setResult] = useState(null);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [redeemCode, setRedeemCode] = useState('');
  const [hasScratched, setHasScratched] = useState(false); // Track if user has scratched today
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false); // Control modal visibility

  // Define the rewards in an array
  const rewards = [
    "10% off",
    "5% off",
    "50% off",
    "Better luck next time",
    "10 Rs",
    "1 Rs"
  ];

  const containerRef = useRef(null);

  // Check if the user has already scratched today
  useEffect(() => {
    const lastScratchDate = localStorage.getItem('lastScratchDate');
    const today = new Date().toISOString().slice(0, 10);
    if (lastScratchDate === today) {
      setHasScratched(true); // User has already scratched today
    } else {
      initializeScratchCard();
    }
  }, []);

  const initializeScratchCard = () => {
    if (containerRef.current) {
      const sc = new ScratchCardJS(containerRef.current, {
        scratchType: SCRATCH_TYPE.CIRCLE,
        containerWidth: containerRef.current.offsetWidth,
        containerHeight: 300,
        imageForwardSrc: scratchCardImage,
        clearZoneRadius: 30,
        percentToFinish: 50,
        callback: handleScratch,
      });
      sc.init();
    }
  };

  const handleScratch = () => {
    // Prevent scratching if already scratched today
    if (hasScratched) {
      setModalMessage('You can only scratch once per day!'); // Inform the user
      setShowModal(true);
      return;
    }

    const randomIndex = Math.floor(Math.random() * rewards.length);
    const scratchResult = rewards[randomIndex];
    setResult(scratchResult);
    setScratched(true);
    setHasScratched(true); // Set that user has scratched
    localStorage.setItem('lastScratchDate', new Date().toISOString().slice(0, 10)); // Save the date of the last scratch
    generateMathQuestion(); // Generate and display the math question
  };

  const generateMathQuestion = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    const correctAnswer = num1 + num2; // Simple addition question
    setAnswer(correctAnswer); // Store the answer for validation
    setQuestion(`What is ${num1} + ${num2}?`); // Set the question
  };

  const handleAnswerSubmit = () => {
    if (parseInt(userAnswer) === answer) {
      const code = `REDEEM${Math.floor(1000 + Math.random() * 9000)}`; // Generate a random redeem code
      setRedeemCode(code);
      setModalMessage(`Correct! Your redeem code is: ${code}`); // Display success message
      setShowModal(true);
      // Reset question and answer
      setQuestion('');
      setUserAnswer('');
    } else {
      setModalMessage('Incorrect answer. Try again!'); // Display error message
      setShowModal(true);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(redeemCode);
    setModalMessage('Redeem code copied to clipboard!'); // Display copy confirmation message
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage(''); // Clear the message when closing the modal
  };

  return (
    <div className="scratch-card-container" ref={containerRef} style={{ margin: '0 auto', position: 'relative', width: '300px', height: '300px' }}>
      {showModal && <Modal message={modalMessage} onClose={closeModal} />}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        {scratched && (
          <div className="result">
            <h2>You won: {result}!</h2> {/* Display the reward */}
          </div>
        )}
        {question && (
          <div className="mt-6 text-center">
            <p className="text-lg">{question}</p>
            <input 
              type="number" 
              value={userAnswer} 
              onChange={(e) => setUserAnswer(e.target.value)} 
              className="mt-2 p-2 border rounded"
              placeholder="Your answer"
            />
            <button 
              onClick={handleAnswerSubmit}
              className="mt-2 bg-gradient-to-r from-blue-400 to-blue-600 text-white font-bold rounded-lg px-4 py-2 transition duration-300"
            >
              Submit Answer
            </button>
          </div>
        )}
        {redeemCode && (
          <div className="mt-6 text-center">
            <p className="text-lg font-bold">Your redeem code is: {redeemCode}</p>
            <button 
              onClick={handleCopy}
              className="mt-2 bg-gradient-to-r from-green-400 to-green-600 text-white font-bold rounded-lg px-4 py-2 transition duration-300"
            >
              Copy Code
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScratchCard;
