import React, { useState, useEffect } from 'react';

// Function to get a cookie by name
const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

const Settings = () => {
    const [emailNotifications, setEmailNotifications] = useState(() => {
        return JSON.parse(localStorage.getItem('emailNotifications')) ?? true; // Default to true
    });
    const [pushNotifications, setPushNotifications] = useState(() => {
        return JSON.parse(localStorage.getItem('pushNotifications')) ?? false; // Default to false
    });
    const [isSaving, setIsSaving] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false); // New loading state for password change
    const [message, setMessage] = useState('');

    // Password change states
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');

    const handleEmailToggle = () => {
        setEmailNotifications((prev) => !prev);
    };

    const handlePushToggle = () => {
        setPushNotifications((prev) => !prev);
    };

    const handleSaveChanges = () => {
        setIsSaving(true);

        // Save settings to the backend
        fetch('/api/update-userprofile', { // Use the appropriate route to update user settings
            method: 'PUT', // Assuming you want to update the user profile
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                emailNotifications,
                pushNotifications,
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Save to localStorage only if successful
                    localStorage.setItem('emailNotifications', JSON.stringify(emailNotifications));
                    localStorage.setItem('pushNotifications', JSON.stringify(pushNotifications));
                    setMessage('Settings saved successfully!');
                } else {
                    setMessage(data.message || "Error saving settings.");
                }
            })
            .catch(err => {
                console.error(err);
                setMessage("An error occurred. Please try again.");
            })
            .finally(() => {
                setIsSaving(false);
                setTimeout(() => setMessage(''), 3000); // Clear message after 3 seconds
            });
    };

    const handleChangePassword = () => {
        // Basic validation
        if (newPassword !== confirmPassword) {
            setPasswordMessage("New passwords do not match!");
            return;
        }
    
        setIsChangingPassword(true); // Start loading
    
        // Get the token from local storage (or wherever you store it)
        const token = localStorage.getItem('token'); // Adjust if your token is stored elsewhere
    
        // Save new password to the backend
        fetch(`${process.env.REACT_APP_API_URL}/api/change-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Include the token in the request
            },
            body: JSON.stringify({
                currentPassword,
                newPassword,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(data => {
                if (data.success) {
                    setPasswordMessage("Password changed successfully!");
                } else {
                    setPasswordMessage(data.message || "Error changing password.");
                }
            })
            .catch(err => {
                console.error("Fetch error:", err);
                setPasswordMessage("An error occurred. Please try again.");
            })
            .finally(() => {
                setIsChangingPassword(false); // Stop loading
                setTimeout(() => setPasswordMessage(''), 3000); // Clear password message after 3 seconds
            });
    };
    

    // Effect to load settings from localStorage when the component mounts
    useEffect(() => {
        const savedEmailNotifications = localStorage.getItem('emailNotifications');
        const savedPushNotifications = localStorage.getItem('pushNotifications');

        if (savedEmailNotifications !== null) {
            setEmailNotifications(JSON.parse(savedEmailNotifications));
        }
        if (savedPushNotifications !== null) {
            setPushNotifications(JSON.parse(savedPushNotifications));
        }
    }, []); // Run only once on mount

    return (
        <div className='max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg'>
            <h2 className='text-2xl font-bold mb-6'>Settings</h2>
            {message && <div className='mb-4 text-green-500'>{message}</div>}
            <div className='mb-4 flex items-center justify-between'>
                <label className='text-lg'>Email Notifications</label>
                <div className='relative cursor-pointer' onClick={handleEmailToggle}>
                    <input
                        type='checkbox'
                        checked={emailNotifications}
                        readOnly
                        className='toggle-checkbox hidden'
                    />
                    <span
                        className={`toggle-slider ${emailNotifications ? 'bg-red-500' : 'bg-gray-300'}`}
                        style={{
                            display: 'block',
                            width: '40px',
                            height: '20px',
                            borderRadius: '10px',
                            position: 'relative',
                        }}
                    >
                        <span
                            style={{
                                display: 'block',
                                width: '18px',
                                height: '18px',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: '50%',
                                left: emailNotifications ? '20px' : '2px',
                                transform: 'translateY(-50%)',
                                transition: 'left 0.2s',
                            }}
                        />
                    </span>
                </div>
            </div>
            <div className='mb-4 flex items-center justify-between'>
                <label className='text-lg'>Push Notifications</label>
                <div className='relative cursor-pointer' onClick={handlePushToggle}>
                    <input
                        type='checkbox'
                        checked={pushNotifications}
                        readOnly
                        className='toggle-checkbox hidden'
                    />
                    <span
                        className={`toggle-slider ${pushNotifications ? 'bg-red-500' : 'bg-gray-300'}`}
                        style={{
                            display: 'block',
                            width: '40px',
                            height: '20px',
                            borderRadius: '10px',
                            position: 'relative',
                        }}
                    >
                        <span
                            style={{
                                display: 'block',
                                width: '18px',
                                height: '18px',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: '50%',
                                left: pushNotifications ? '20px' : '2px',
                                transform: 'translateY(-50%)',
                                transition: 'left 0.2s',
                            }}
                        />
                    </span>
                </div>
            </div>
            <button
                onClick={handleSaveChanges}
                disabled={isSaving}
                className={`px-4 py-2 ${isSaving ? 'bg-gray-400' : 'bg-blue-500'} text-white rounded-md shadow hover:bg-blue-600 transition`}
            >
                {isSaving ? 'Saving...' : 'Save Changes'}
            </button>

            {/* Change Password Section */}
            <div className='mt-8'>
                <h3 className='text-xl font-bold mb-4'>Change Password</h3>
                <button
                    onClick={() => setShowPasswordModal(true)}
                    className='px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition'
                >
                    Change Password
                </button>
            </div>

            {/* Password Modal */}
            {showPasswordModal && (
                <div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50'>
                    <div className='bg-white p-6 rounded-lg shadow-lg'>
                        <h4 className='text-lg font-bold mb-4'>Change Your Password</h4>
                        {passwordMessage && <div className='mb-4 text-red-500'>{passwordMessage}</div>}
                        <div className='mb-4'>
                            <label className='block mb-2'>Current Password</label>
                            <input
                                type='password'
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                className='border border-gray-300 p-2 rounded w-full'
                                placeholder='Current Password'
                            />
                        </div>
                        <div className='mb-4'>
                            <label className='block mb-2'>New Password</label>
                            <input
                                type='password'
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className='border border-gray-300 p-2 rounded w-full'
                                placeholder='New Password'
                            />
                        </div>
                        <div className='mb-4'>
                            <label className='block mb-2'>Confirm New Password</label>
                            <input
                                type='password'
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className='border border-gray-300 p-2 rounded w-full'
                                placeholder='Confirm New Password'
                            />
                        </div>
                        <button
                            onClick={handleChangePassword}
                            disabled={isChangingPassword}
                            className={`px-4 py-2 ${isChangingPassword ? 'bg-gray-400' : 'bg-blue-500'} text-white rounded-md shadow hover:bg-blue-600 transition`}
                        >
                            {isChangingPassword ? 'Changing...' : 'Change Password'}
                        </button>
                        <button
                            onClick={() => setShowPasswordModal(false)}
                            className='mt-4 text-red-500'
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;
