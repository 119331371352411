import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {  FaUsers, FaChartLine } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ROLE from '../common/role';

const AdminPanel = () => {
    const user = useSelector(state => state?.user?.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.role !== ROLE.ADMIN) {
            navigate("/");
        }
    }, [user]);

    return (
        <div className='min-h-[calc(100vh-120px)] md:flex'>

            {/* Sidebar */}
            <aside className='bg-white min-h-full w-60 p-4 customShadow'>
                <div className='h-40 flex justify-center items-center flex-col'>
                    <div className='text-5xl cursor-pointer relative flex justify-center'>
                        {user?.profilePic ? (
                            <img src={user?.profilePic} className='w-20 h-20 rounded-full' alt={user?.name} />
                        ) : (
                            <FaCircleUser />
                        )}
                    </div>
                    <p className='capitalize text-lg font-semibold'>{user?.name}</p>
                    <p className='text-sm'>{user?.role}</p>
                </div>

                {/* Navigation Links */}
                <nav className='mt-6'>
                    <Link to={"all-users"} className='flex items-center px-4 py-2 hover:bg-slate-100'>
                        <FaUsers className="mr-2" /> All Users
                    </Link>
                    <Link to={"all-products"} className='flex items-center px-4 py-2 hover:bg-slate-100'>
                        <FaChartLine className="mr-2" /> All Products
                    </Link>
                </nav>
            </aside>

            {/* Main Content */}
            <main className='w-full h-full p-4 bg-gray-100'>
              


                {/* Outlet for Nested Routes */}
                <Outlet />
            </main>
        </div>
    );
};

export default AdminPanel;
