import React, { useContext, useEffect, useRef, useState } from 'react';
import Logo from './Logo';
import { GrSearch } from "react-icons/gr";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { setUserDetails } from '../store/userSlice';
import ROLE from '../common/role';
import Context from '../context';

const Header = () => {
  const user = useSelector(state => state?.user?.user);
  const dispatch = useDispatch();
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [mobileSearchVisible, setMobileSearchVisible] = useState(false);
  const context = useContext(Context);
  const navigate = useNavigate();
  const searchInput = useLocation();
  const URLSearch = new URLSearchParams(searchInput?.search);
  const searchQuery = URLSearch.getAll("q");
  const [search, setSearch] = useState(searchQuery);
  const menuRef = useRef(null);
  const mobileSearchRef = useRef(null);

  const handleLogout = async () => {
    const fetchData = await fetch(SummaryApi.logout_user.url, {
      method: SummaryApi.logout_user.method,
      credentials: 'include'
    });

    const data = await fetchData.json();

    if (data.success) {
      toast.success(data.message);
      dispatch(setUserDetails(null));
      navigate("/");
    }

    if (data.error) {
      toast.error(data.message);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (value) {
      navigate(`/search?q=${value}`);
    } else {
      navigate("/search");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuDisplay(false);
      }
      if (mobileSearchRef.current && !mobileSearchRef.current.contains(event.target)) {
        setMobileSearchVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <header className='h-16 shadow-md bg-white fixed w-full z-40'>
      <div className='h-full container mx-auto flex items-center px-4 justify-between'>
        <div>
          <Link to={"/"}>
            <Logo w={135} h={65} />
          </Link>
        </div>

        {/* Show Search Only if User is Logged In */}
        {user?._id && (
          <>
            {/* Desktop Search */}
            <div className='hidden lg:flex items-center w-full justify-between max-w-sm border rounded-full focus-within:shadow pl-2'>
              <input
                type='text'
                placeholder='search product here...'
                className='w-full outline-none px-4 py-2 rounded-l-full'
                onChange={handleSearch}
                value={search}
              />
              <div className='text-lg min-w-[50px] h-10 bg-red-600 flex items-center justify-center rounded-r-full text-white'>
                <GrSearch />
              </div>
            </div>

            {/* Mobile Search */}
            <div className='lg:hidden flex items-center gap-4'>
              <button
                onClick={() => setMobileSearchVisible(prev => !prev)}
                className='text-2xl'
              >
                <FaSearch />
              </button>

              {mobileSearchVisible && (
                <div ref={mobileSearchRef} className='absolute top-16 left-0 w-full bg-white shadow-lg rounded-lg'>
                  <div className='relative'>
                    <input
                      type='text'
                      placeholder='search product here...'
                      className='w-full px-4 py-2 rounded-lg border pr-10'
                      onChange={handleSearch}
                      value={search}
                    />
                    <div className='absolute right-2 top-1/2 transform -translate-y-1/2 text-lg text-red-600'>
                      <FaSearch />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <div className='flex items-center gap-7'>
          <div className='relative flex items-center'>
            {user?._id && (
              <div className='relative flex items-center'>
                <div
                  className='text-3xl cursor-pointer relative flex items-center'
                  onClick={() => setMenuDisplay(prev => !prev)}
                >
                  {user?.profilePic ? (
                    <img src={user?.profilePic} className='w-10 h-10 rounded-full' alt={user?.name} />
                  ) : (
                    <FaRegCircleUser />
                  )}
                </div>

                {menuDisplay && (
                  <div
                    ref={menuRef}
                    className='absolute right-0 mt-20 w-32 bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200'
                  >
                    <nav className='flex flex-col'>
                      <div className='hidden lg:block'>
                        {user?.role === ROLE.ADMIN && (
                          <Link
                            to={"/admin-panel/all-products"}
                            className='block hover:bg-gray-100 px-4 py-2 text-gray-800'
                            onClick={() => setMenuDisplay(false)}
                          >
                            Admin Panel
                          </Link>
                        )}
                      </div>
                      <Link
                        to={`/profile/${user?._id}`}
                        className='block hover:bg-gray-100 px-4 py-2 text-gray-800'
                        onClick={() => setMenuDisplay(false)}
                      >
                        Profile
                      </Link>
                    </nav>
                  </div>
                )}
              </div>
            )}
          </div>

          {user?._id && (
            <Link to={"/cart"} className='text-2xl relative'>
              <span><FaShoppingCart /></span>
              <div className='bg-red-600 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
                <p className='text-sm '>{context?.cartProductCount}</p>
              </div>
            </Link>
          )}

          <div>
            {user?._id ? (
              <button
                onClick={handleLogout}
                className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700'
              >
                Logout
              </button>
            ) : (
              <Link
                to={"/login"}
                className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700'
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
