import React, { useState, useContext } from 'react';
import loginIcons from '../assest/signin.gif';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import Context from '../context';
import './Login.css'; // Import the CSS file

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: ""
  });

  const navigate = useNavigate();
  const { fetchUserDetails } = useContext(Context);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(SummaryApi.signIn.url, {
        method: SummaryApi.signIn.method,
        credentials: 'include', // Ensure cookies are sent with requests
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
  
      const dataApi = await response.json();
  
      if (dataApi.success) {
        // Store token in localStorage
        localStorage.setItem('token', dataApi.token); // Adjust based on how the token is sent in response
  
        // Custom success toast
        toast.success(dataApi.message, {
          className: 'custom-toast',
          bodyClassName: 'custom-toast-body',
          progressClassName: 'custom-toast-progress',
          position: "top-right",
          autoClose: 3000
        });
        navigate('/'); // Redirect after successful login
        fetchUserDetails(); // Update user details
      } else if (dataApi.error) {
        // Custom error toast
        toast.error(dataApi.message, {
          className: 'custom-toast',
          bodyClassName: 'custom-toast-body',
          progressClassName: 'custom-toast-progress',
          position: "top-right",
          autoClose: 3000
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        className: 'custom-toast',
        bodyClassName: 'custom-toast-body',
        progressClassName: 'custom-toast-progress',
        position: "top-right",
        autoClose: 3000
      });
    }
  };
  

  return (
    <section id='login'>
      <div className='login-container'>
        <div className='login-message'>
          <h1>Welcome Back!</h1>
          <p>Please log in to continue to our amazing platform.</p>
        </div>
        <div className='login-box'>
          <div className='login-logo'>
            <img src={loginIcons} alt='login icons' />
          </div>
          <form className='login-form' onSubmit={handleSubmit}>
            <div className='form-group'>
              <label>Email:</label>
              <input
                type='email'
                placeholder='Enter your email'
                name='email'
                value={data.email}
                onChange={handleOnChange}
                required
              />
            </div>
            <div className='form-group'>
              <label>Password:</label>
              <div className='password-container'>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder='Enter your password'
                  name='password'
                  value={data.password}
                  onChange={handleOnChange}
                  required
                />
                <div className='password-toggle' onClick={() => setShowPassword(prev => !prev)}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              <Link to={'/forgot-password'} className='forgot-password'>
                Forgot password?
              </Link>
            </div>
            <button type='submit' className='login-button'>
              Sign In
            </button>
            <div className='create-account'>
              <p>New to our platform?</p>
              <Link to="/sign-up" className='signup-link'>
                Create an account
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Login;
